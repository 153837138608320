<template>
  <div class="lecturerItem">
    <div class="tl lecturer">讲师</div>
    <div class="arrow" v-if="lists.length > 1">
      <i class="el-icon-arrow-left" @click="avtiveChange(0)"></i>
      <i class="el-icon-arrow-right" @click="avtiveChange(1)"></i>
    </div>
    <div v-for="(item, index) in lists" :key="index">
      <div v-if="avtiveIndex == index">
        <el-image
          :src="item.avatar ? item.avatar : DEFAULT_AVATARSQUARE"
          class="avatar"
        ></el-image>
        <div>{{ item.name_en | priorFormat(item.name_zh, LOCALE) }}</div>
        <div
          class="profile"
          v-html="item.desc_zh || item.desc_en || '-'"
          v-if="!item.desc"
        ></div>
        <div class="profile" v-html="item.desc || '-'" v-else></div>
        <div class="mainColor getMore" v-if="item.desc">
          <span class="cursor" @click="getMore(index)"
            ><i class="el-icon-arrow-down"></i>查看更多</span
          >
        </div>
      </div>
    </div>
    <noDataImg v-if="lists.length == 0" />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      avtiveIndex: 0,
      isShow: false,
      olistHeight: false,
      lists: [],
    };
  },
  watch: {
    list() {
      this.lists = this.list;
      this.divHeight();
    },
    avtiveIndex(index) {
      // let desc = this.list[index].desc_zh || this.list[index].desc_en;
      // this.$nextTick((e) => {
      //   if (desc.length > 143) {
      //     this.olistHeight = true;
      //   } else {
      //     this.olistHeight = false;
      //   }
      // });
      this.divHeight();
    },
  },
  mounted() {
    this.lists = this.list;
    this.divHeight();
  },
  methods: {
    avtiveChange(type) {
      this.isShow = false;
      if (type) {
        if (this.avtiveIndex == this.list.length - 1) {
          this.avtiveIndex = 0;
        } else {
          this.avtiveIndex++;
        }
      } else {
        if (this.avtiveIndex == 0) {
          this.avtiveIndex = this.list.length - 1;
        } else {
          this.avtiveIndex--;
        }
      }
    },
    divHeight() {
      this.$nextTick((e) => {
        let list = JSON.parse(JSON.stringify(this.list));
        list.forEach((item) => {
          let decs = item.desc_zh || item.desc_en;
          if (decs && decs.length > 152) {
            item.desc = decs.substring(0, 152);
          } else {
            item.desc = "";
          }
        });
        this.lists = list;
      });
    },
    getMore(index) {
      this.lists[index].desc = "";
    },
  },
  // computed:{
  //   olistHeight(){
  //     var olist = document.getElementById("list");
  //       if (olist.clientHeight > 150) {
  //        return true;
  //       } else {
  //        return false;
  //       }
  //   }
  // }
};
</script>

<style lang="less" scoped>
.lecturerItem {
  text-align: center;
  position: relative;
  height: 100%;
  min-height: 200px;
  .profile {
    text-align: left;
    margin: 12px 24px 12px;
    white-space: pre-line;
    word-wrap: break-word;
  }
  // .textHidden {
  //   overflow: hidden;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   -webkit-line-clamp: 8;
  //   overflow: hidden;
  // }
  .arrow {
    position: absolute;
    top: 12px;
    right: 12px;
    i {
      margin-left: 5px;
      color: #ccc;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
.getMore {
  padding-bottom: 16px;
  i {
    margin-right: 5px;
    cursor: pointer;
  }
}
.lecturer {
  padding: 10px 24px;
  font-weight: bold;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
</style>