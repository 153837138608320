<template>
  <div class="catalog">
    <div v-for="(item, index) in list">
      <div class="name">
        <el-tag size="small" type="success" v-if="item.is_free_try"
          >免费</el-tag
        >
        <span v-if="item.name">{{ index + 1 }}&nbsp {{ item.name }}</span>
      </div>
      <div class="content">
        <div
          v-for="its in item.resource"
          class="resource cursor"
          @click="toWacth(its, item.is_free_try)"
        >
          <template v-if="item.is_video">
            <img
              style="margin-right: 10px"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/videoIcon.png"
            />
            <span>{{ its.name }}</span>
          </template>
          <template v-else>
            <img
              style="margin-right: 10px"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/fileIcon.png"
            />
            <span>{{ its.file_name }}</span>
          </template>
        </div>
        <template v-if="item.is_video == 1">
          <div
            v-for="its in item.ware"
            class="resource cursor"
            @click="toWacth(its, item.is_free_try)"
          >
            <img
              style="margin-right: 10px"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/fileIcon.png"
            />
            <span>{{ its.file_name }}</span>
          </div></template
        >
      </div>
    </div>
    <noDataImg v-if="list.length == 0" />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    toWacth(item, type) {
      this.$emit("watchChange", item, type);
    },
  },
};
</script>

<style lang="less" scoped>
.catalog {
  height: 600px;
  overflow: auto;
  position: relative;
  .name {
    font-size: 16px;
    font-weight: bold;
    margin: 24px 0 16px;
  }
  .resource {
    padding: 12px 15px;
    background-color: #f3f3f3;
    margin-bottom: 10px;
  }
}
</style>