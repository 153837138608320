<template>
  <div class="imgSilder" :id="ranDomId">
    <el-carousel
      :initial-index="value1"
      :arrow="imgList.length > 1 ? 'always' : 'never'"
      dots="none"
      :height="imgOption.height"
      :autoplay="autoPlay"
      :indicator-position="imgList.length > 1 ? 'outside' : 'none'"
      trigger="click"
      :loop="true"
    >
      <el-carousel-item
        v-for="(item, index) in imgList"
        :key="index"
        :name="index + ''"
      >
        <div class="demo-carousel clearfix">
          <div
            class="fl"
            v-for="(ele, index) in item"
            :key="index"
            :style="divStyle"
          >
            <el-image
              :src="ele.cover_url"
              fit="cover"
              style="cursor: pointer"
              @click="visitPreview(ele)"
            >
              <div slot="placeholder" class="image-slot">
                <div class="loading">{{ $t("loadingIng") }}</div>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="textEle">{{ ele.name }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-dialog
      fullscreen
      :visible.sync="showDialog"
      class="imgPreivewDialog"
      :show-close="false"
    >
      <el-image
        class="diaLogImage"
        style="width: 0px; height: 0px"
        :src="previewList[0]"
        :preview-src-list="previewList"
        @closePreview="showDialog = false"
        ref="diaLogImage"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { splitArr } from "~/baseUtils";
export default {
  components: {},
  props: {
    autoPlay: {
      type: Boolean,
      default: false,
    },
    imgOption: {
      //width:'256px'
      //height:'256px',
      // offset:""
      //totalWidth:600,
      //imgList:[]
    },
  },
  data() {
    return {
      ranDomId: "",
      value1: 0,
      imgList: [],
      previewList: [],
      offset: 0,
      bigImg: "",
      imgModel: false,
      bigWidth: 0,

      bigHeight: 0,
      showDelete: false,
      showDialog: false,
      divStyle: {
        width: this.imgOption.width,
        height: this.imgOption.height,
      },
    };
  },

  mounted() {
    this.handleShow();
    this.ranDomId = `id${this.getKey()}`;
  },
  activated() {
    this.handleShow();
  },
  methods: {
    visitPreview(ele) {
      this.$emit("imgChange", ele);
      // this.showDialog = true;
      // this.$nextTick(() => {
      //   this.$nextTick(() => {
      //     this.$refs.diaLogImage.clickHandler(ele);
      //   });
      // });
    },
    getKey() {
      return Math.random().toString(36).substr(2);
    },
    handleShow() {
      this.previewList = [].concat(this.imgOption.imgList);
      let oneWidth = parseInt(this.imgOption.width);
      let totalWidth = this.imgOption.totalWidth;
      let arrSize = parseInt(totalWidth / oneWidth);
      this.divStyle = {
        width: this.imgOption.width,
        height: this.imgOption.height,
        marginRight: "18px",
        // parseInt(
        //   (parseFloat("0." + (totalWidth / oneWidth + "").substr(2)) *
        //     oneWidth) /
        //     (arrSize - 1)
        // ) + "px",
      };
      let handleImgList = splitArr(this.imgOption.imgList, arrSize);
      this.imgList = handleImgList;
      let position;
      if (this.imgOption.offset) {
        position = this.imgOption.offset;
      } else {
        position = (652 - this.imgList.length * 14) / 2 - 20;
      }
      if (this.imgList.length > 1) {
        this.$nextTick(() => {
          // document.querySelector(
          //   `#${this.ranDomId} .el-carousel__arrow--left`
          // ).style.left = `${position}px`;
          // document.querySelector(
          //   `#${this.ranDomId} .el-carousel__arrow--right`
          // ).style.right = `${position}px`;
        });
      }
    },
  },
  watch: {
    "imgOption.imgList": {
      deep: true,
      handler(nv) {
        this.handleShow();
      },
    },
  },
};
</script>

<style  scoped lang='less'>
.imgPreivewDialog {
  /deep/ .el-dialog {
    border-radius: 0px;
    background: transparent;
  }
  /deep/ .el-dialog__header {
    background: transparent;
    border-radius: 0px;
    border-bottom: 0 none;
  }
}
.diaLogImage {
  background: transparent;
  /deep/ & > img {
    display: none;
  }
}
.imgSilder {
  width: 100%;
  /deep/ .el-carousel__indicator--horizontal {
    display: none;
    .el-carousel__button {
      width: 6px;
      height: 6px;
      background: #d1d1d1;
      border-radius: 50%;
    }
    &.is-active {
      .el-carousel__button {
        background: #1890ff;
      }
    }
  }
  /deep/ .el-carousel__arrow {
    width: 26px;
    height: 26px;
    bottom: 70px;
    background: #696969;
  }
  /deep/ .el-carousel__arrow--left {
    left: 0px;
    top: unset;
    .el-icon-arrow-left {
      font-weight: 600;
      color: #fff;
    }
  }
  /deep/ .el-carousel__arrow--right {
    right: 0;
    top: unset;
    z-index: 10;
    .el-icon-arrow-right {
      font-weight: 600;
      color: #fff;
    }
  }
  .demo-carousel {
    padding: 0 10px;
    .fl {
      position: relative;
      // background: #f5f7fa;
    }
    .textEle {
      color: #333;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
    }
    .fl:last-child {
      margin-right: 0px !important;
    }
    .el-image {
      width: 100%;
      height: calc(100% - 45px);
      background: rgb(242, 242, 242);
    }
    img {
      width: 100%;
      height: 100%;
    }
    .image-slot {
      text-align: center;
      line-height: 100%;
      width: 100%;
      height: 100%;
      background: rgb(242, 242, 242);
      color: #999;
      font-size: 30px;
      position: relative;
      .el-icon-picture-outline,
      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .loading {
        font-size: 12px;
      }
    }
  }
  .fl {
    position: relative;
  }
}
</style>
