import { render, staticRenderFns } from "./columnDetail.vue?vue&type=template&id=96071298&scoped=true"
import script from "./columnDetail.vue?vue&type=script&lang=js"
export * from "./columnDetail.vue?vue&type=script&lang=js"
import style0 from "./columnDetail.vue?vue&type=style&index=0&id=96071298&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96071298",
  null
  
)

export default component.exports