<template>
  <div class="detail">
    <div class="topBox clearfix">
      <div class="boxLeft fl">
        <el-image
          v-if="!aliyun_id"
          :src="info.cover_url"
          class="leftImage"
        ></el-image>
        <AliyunVideo
          v-else
          ref="AliyunVideo"
          class="liveThumb"
          :AliyunId="aliyun_id"
          :pre_view_time="previewTime"
          :danmukuList="danmukuList"
        ></AliyunVideo>
      </div>
      <div class="boxRight fl">
        <div class="title clearfix">
          <div class="fl" :title="info.name | textFormat">
            {{ info.name | textFormat }}
          </div>
          <div class="fr">
            <!-- <i class="el-icon-star-off cursor"></i> -->
            <!-- <i class="el-icon-star-on cursor"></i> -->
          </div>
        </div>
        <div class="content clearfix">
          <!-- <div class="fl div">2586人已学过</div> -->
          <div
            class="fl textOverflow"
            v-if="info.lecturer && info.lecturer.length"
          >
            讲师：<span v-for="(item, index) in info.lecturer" class="marginL">
              <!-- <span v-if="index != 0">&nbsp</span >-->
              {{ item.name_en | priorFormat(item.name_zh, "zh") }}</span
            >
          </div>
        </div>
        <div class="content clearfix">
          <template v-if="info.is_free">
            <div class="parce1">免费</div>
          </template>
          <template v-else>
            <!-- <div class="fl parce">￥{{ info.price_actual }}</div>
            <div class="fl throgh" v-if="info.price_scribe">
              ￥{{ info.price_scribe }}
            </div> -->
            <div class="newPrice">
              <span class="p1">￥{{info.price_actual}}</span>
              <img :src="img" alt="">
            </div>
            <div class="newPrice" style="padding-top:12px"> 
              <span class="p2">非会员价：￥{{info.price_scribe}}</span>
            </div>
          </template>
        </div>
        <!-- <div class="content clearfix">
          <el-button type="primary" class="button2" round size="small"
            >精选好课，VIP享9折 立即开通 >></el-button
          >
        </div> -->
        <div class="content clearfix">
          <el-button
            v-if="!info.is_free"
            type="primary"
            class="button1"
            @click="saveBuy"
            :disabled="info.is_buy ? true : false"
          >
            {{ info.is_buy ? "已购买" : "立即购买" }}
          </el-button>
          <el-button v-else type="primary" class="button1" @click="saveBuy">
            立即学习
          </el-button>
          <!-- <el-button type="primary" plain>免费试看</el-button> -->
        </div>
        <div class="fbuy" v-if="!info.is_free">{{ info.num_fbuy }} 人购买</div>
      </div>
    </div>
    <div class="courseMain clearfix">
      <div class="courseLeft fl">
        <div class="slider">
          <imgSlider
            :imgOption="imgOption"
            @imgChange="sliderChange"
          ></imgSlider>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleTabs">
          <el-tab-pane label="介绍" name="first">
            <p
              style="white-space: pre-line"
              v-html="info.desc"
              class="introduce"
            ></p>
          </el-tab-pane>
          <!-- <el-tab-pane label="目录" name="second">
            <catalog
              :list="info.class_info"
              @watchChange="watchChange"
            ></catalog>
          </el-tab-pane> -->
          <el-tab-pane label="评价" name="third">
            <comment></comment>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="courseRight fr">
        <lecturerItem :list="info.lecturer" ></lecturerItem>
      </div>
    </div>
  </div>
</template>
<script>
import AliyunVideo from "~/baseComponents/AliyunVideoPre";
import comment from "~tcb/components/college/comment";
import lecturerItem from "~tcb/components/college/lecturerItem";
import catalog from "~tcb/components/college/catalog";
import imgSlider from "~tcb/components/common/imgSlider1";

import "@/baseAssets/icon/iconfont.css";
export default {
  components: {
    comment,
    AliyunVideo,
    lecturerItem,
    catalog,
    imgSlider,
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
     danmukuList:{
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  data() {
    return {
      img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/icon_hy.png',
      activeName: "first",
      video_id: "",
      item: {},
      isSoucang: false,
      aliyun_id: null,
      previewTime: 5,
      imgOption: {
        width: "187px",
        height: "153px",
        totalWidth: 850,
        imgList: [],
      },
    };
  },
  watch: {
    info() {
      if (this.info.class_info && this.info.class_info.length) {
        this.$nextTick((e) => {
          this.imgOption.imgList = this.info.class_info[0].resource;
        });
      }
    },
  },
  mounted() {
    // // 初始化 视频播放次数+1
    // this.video_id = this._decode(this.$route.query.parameter).video_id;
    // await this.$store.dispatch(
    //   "API_company/play_count_Add",
    //   this._decode(this.$route.query.parameter)
    // );
    // // 获取视频信息
    // this.getVideo();
    // // 检查是否已经收藏了视频
    // this.cheechSC();
  },
  methods: {
    sliderChange(item) {
      if (this.info.is_buy == 1 || this.info.is_free == 1) {
        this.previewTime = 0;
        this.aliyun_id = item.aliyun_id;
      } else {
        this.previewTime = 180;
        this.aliyun_id = item.aliyun_id;
      }
      if (item.aliyun_id) {
        this.$nextTick((e) => {
          this.$refs.AliyunVideo.handlePlay();
        });
      }
    },
    async getVideo() {
      let params = {
        source: this.PJSource,
        video_id: this.video_id,
      };
      let res = await this.$store.dispatch(
        "API_company/association_video_List",
        params
      );
      this.item = res.data[0];
    },
    saveBuy() {
      if (this.info.is_free == 1) {
        let imgList = {};
        if (this.imgOption.imgList && this.imgOption.imgList.length) {
          imgList = this.imgOption.imgList[0];
          this.sliderChange(imgList);
        } else {
          this.$message.warning("当前课程暂无内容");
        }
      } else {
        this.$emit("buyChange");
      }
    },
    handleTabs() {},
    async cheechSC() {
      if (!this.USER_INFO.id) {
        this.isSoucang = false;
        return;
      }
      let params = {
        user_id: this.USER_INFO.id,
        video_id: this.video_id,
      };
      let res = await this.$store.dispatch(
        "API_company/check_video_SC",
        params
      );
      if (res.is_like == 0) {
        this.isSoucang = false;
      } else {
        this.isSoucang = true;
      }
    },
    async handleSC() {
      let params = {
        user_id: this.USER_INFO.id,
        video_id: this.video_id,
        like_type: this.isSoucang ? 0 : 1,
      };
      let res = await this.$store.dispatch(
        "API_company/association_video_Like",
        params
      );
      this.isSoucang = !this.isSoucang;
      if (this.isSoucang) {
        this.$message.success("已收藏");
      } else {
        this.$message.info("取消收藏");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.detail {
  width: 1200px;
  margin: auto;
  .topBox {
    height: 310px;
    background: #fff;
    box-shadow: 0px 1px 7px 0px rgba(156, 156, 156, 0.5);
    .leftImage {
      width: 460px;
      height: 262px;
      margin: 25px;
    }
    .liveThumb {
      width: 460px;
      height: 262px;
      position: relative;
      margin: 25px;
      .enterBtn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .boxRight {
      padding: 25px 25px 25px 0;
      width: 690px;
      .title {
        font-size: 22px;
        width: 100%;
        .fr {
          color: #999999;
        }
        // .textOverflow {
        //   width: 90%;
        // }
      }
      .content {
        width: 100%;
        font-size: 14px;
        margin-top: 14px;

        .div {
          margin-right: 30px;
          color: #999999;
        }
        .parce1 {
          color: #f13c3c;
          margin-right: 14px;
          font-size: 20px;
        }
        .parce {
          color: #f13c3c;
          margin-right: 14px;
          font-size: 26px;
        }
        .newPrice{
          display: flex;
          align-items: center;
          .p1{
            font-size: 26px;
            font-weight: 400;
            color: #F13C3C;
          }
          .p2{
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }
        }
        .throgh {
          text-decoration: line-through;
          font-size: 16px;
          margin-top: 8px;
          color: #999999;
        }
        .button1 {
          margin-top: 50px;
        }
        .button2 {
          background: linear-gradient(90deg, #ff050a 0%, #ffae00 100%);
          margin-bottom: 30px;
        }
        .textOverflow {
          width: 100%;
        }
        .marginL {
          margin-right: 10px;
        }
      }
    }
  }
  .courseMain {
    width: 100%;
    margin-top: 20px;
    .courseLeft {
      // height: 700px;
      width: 870px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0px 1px 7px 0px rgba(156, 156, 156, 0.5);
      padding: 0 24px 16px;
      .slider {
        padding: 20px 0;
      }
    }
    .courseRight {
      width: 309px;
      // height: 306px;
      background-color: #fff;
      box-shadow: 0px 1px 7px 0px rgba(156, 156, 156, 0.5);
    }
  }
  /deep/.el-tabs__nav-wrap::after {
    background-color: #dddddd;
    height: 1px;
  }
}
.introduce {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.topic {
  display: flex;
  align-items: center;
}
.fbuy{
  text-align: right;
}
</style>